import React from 'react';
import { Container, Row } from 'react-bootstrap';
import Post1 from '../../../../images/motul/blog-1.jpg';
import Post2 from '../../../../images/motul/blog-2.jpg';
import Post3 from '../../../../images/motul/blog-3.jpg';
import Post4 from '../../../../images/motul/blog-4.jpg';
import BlogCard from '../BlogCard/BlogCard';
import Footer from '../../../Shared/Footer/Footer';
import NavbarComponent from '../../../Shared/NavbarCompoment/NavbarComponent';
import { Button } from 'react-bootstrap';

const Blog = () => {
  const openWhatsApp = () => {
    const whatsappUrl = `https://wa.me/212689520902?text=Bonjour, Je veux prendre un rendez-vous chez AchrafBike Service.`;
    window.open(whatsappUrl, '_blank');
  };
  const posts5100 = [
    {
      name: 'Huile Moto 4 Temps',
      img: Post1,
      text:
        'MOTUL 5100 est une huile moteur pour motos 4T qui répond aux attentes en matière de fiabilité pour une utilisation quotidienne et des activités récréatives. Elle convient à tous les types de motos routières ou tout-terrain équipées d\'un moteur 4 temps, avec ou sans boîte de vitesses intégrée, avec embrayage humide ou sec. Parfaite pour les motos équipées de systèmes de post-traitement des gaz d\'échappement tels que les convertisseurs catalytiques ou l\'injection d\'air secondaire.',
    },
    {
      name: 'Viscosités disponibles',
      img: Post2,
      text:
        '10W-40',
    },
  ];
  const posts7100 = [
    {
      name: 'Huile Moto 4 Temps',
      img: Post3,
      text:
        'MOTUL 7100 est l\'une des meilleures huiles moteur de sa catégorie pour une protection maximale, destinée à fournir les meilleures performances pour la conduite sur route et hors route. Convient à tous les types de motos hautes performances à moteur 4 temps, avec ou sans boîte de vitesses intégrée, embrayage sec ou humide. Parfait pour les motos équipées de systèmes de post-traitement des gaz d\'échappement tels que les convertisseurs catalytiques ou l\'injection d\'air secondaire. Conçu pour les conditions sévères de la conduite sportive et de l\'aventure.',
    },
    {
      name: 'Viscosités disponibles',
      img: Post4,
      text:
        '10W-50',
    },
  ];
  return (
    <>
      <NavbarComponent />
      <main>
        <section>
          <div className="text-center title">
            <h6 className="primary__color font__bold">MOTUL</h6>
            <h2>MOTUL 5100 10W-40 4T</h2>
          </div>
          <Container className="mt-2">
            <Row>
              {posts5100.map((post, idx) => (
              <BlogCard key={idx} post={post} />
              ))}
            </Row>
            <div class="links"><Button className="btn button" onClick={openWhatsApp}>Plus d'infos</Button></div>
          </Container>
        </section>
        <section>
          <div className="text-center title">
            <h6 className="primary__color font__bold">MOTUL</h6>
            <h2>MOTUL 7100 10W-50 4T</h2>
          </div>
          <Container className="mt-2">
            <Row>
              {posts7100.map((post, idx) => (
              <BlogCard key={idx} post={post} />
              ))}
            </Row>
            <div class="links"><Button className="btn button" onClick={openWhatsApp}>Plus d'infos</Button></div>
          </Container>
        </section>
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
};

export default Blog;
