import React from 'react';
import { Container, Row } from 'react-bootstrap';
import Post1 from '../../../../images/ipone/blog-1.jpg';
import Post2 from '../../../../images/ipone/blog-2.jpg';
import Post3 from '../../../../images/ipone/blog-3.jpg';
import Post4 from '../../../../images/ipone/blog-4.jpg';
import Post5 from '../../../../images/ipone/blog-5.jpg';
import Post6 from '../../../../images/ipone/blog-6.jpg';
import BlogCard from '../BlogCard/BlogCard';
import Footer from '../../../Shared/Footer/Footer';
import NavbarComponent from '../../../Shared/NavbarCompoment/NavbarComponent';
import { Button } from 'react-bootstrap';

const Blog = () => {
  const openWhatsApp = () => {
    const whatsappUrl = `https://wa.me/212689520902?text=Bonjour, Je veux prendre un rendez-vous chez AchrafBike Service.`;
    window.open(whatsappUrl, '_blank');
  };
  const postsr4000 = [
    {
      name: 'Huile Moto 4 Temps',
      img: Post1,
      text:
        'Semi-Synthétique à base d’esters. Usage régulier.',
    },
    {
      name: 'Viscosités disponibles',
      img: Post2,
      text:
        '10W-30 / 10W-40 / 10W-50 / 15W-50 / 20W-50',
    },
  ];
  const postsfullpowerkatana = [
    {
      name: 'Huile Moto 4 Temps',
      img: Post3,
      text:
        '100% Synthétique à base d’esters. Conduite sportive sur route.',
    },
    {
      name: 'Viscosités disponibles',
      img: Post4,
      text:
        '5W-40 / 10W-30 / 10W-40 / 10W-50 / 10W-60 / 15W-50',
    },
  ];
  const postskatanaoffroad = [
    {
      name: 'Huile Moto 4 Temps',
      img: Post5,
      text:
        '100% Synthétique à base d’esters. Tout-Terrain.',
    },
    {
      name: 'Viscosités disponibles',
      img: Post6,
      text:
        '10W-40 / 10W-50 / 10W-60',
    },
  ];
  return (
    <>
      <NavbarComponent />
      <main>
        <section>
          <div className="text-center title">
            <h6 className="primary__color font__bold">IPONE</h6>
            <h2>R4000 RS</h2>
          </div>
          <Container className="mt-2">
            <Row>
              {postsr4000.map((post, idx) => (
              <BlogCard key={idx} post={post} />
              ))}
            </Row>
            <div class="links"><Button className="btn button" onClick={openWhatsApp}>Plus d'infos</Button></div>
          </Container>
        </section>
        <section>
          <div className="text-center title">
            <h6 className="primary__color font__bold">IPONE</h6>
            <h2>FULL POWER KATANA</h2>
          </div>
          <Container className="mt-2">
            <Row>
              {postsfullpowerkatana.map((post, idx) => (
              <BlogCard key={idx} post={post} />
              ))}
            </Row>
            <div class="links"><Button className="btn button" onClick={openWhatsApp}>Plus d'infos</Button></div>
          </Container>
        </section>
        <section>
          <div className="text-center title">
            <h6 className="primary__color font__bold">IPONE</h6>
            <h2>KATANA OFF-ROAD</h2>
          </div>
          <Container className="mt-2">
            <Row>
              {postskatanaoffroad.map((post, idx) => (
              <BlogCard key={idx} post={post} />
              ))}
            </Row>
            <div class="links"><Button className="btn button" onClick={openWhatsApp}>Plus d'infos</Button></div>
          </Container>
        </section>
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
};

export default Blog;
