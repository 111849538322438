import React from 'react';
import { Container, Row } from 'react-bootstrap';
import TeamMember1 from '../../../images/team1.jpg';
import TeamMember2 from '../../../images/team2.jpg';
import TeamMember3 from '../../../images/team3.jpg';
import TeamMember4 from '../../../images/team4.jpg';
import TeamCard from '../TeamCard/TeamCard';

const Team = () => {
  const teams = [
    {
      name: 'Achraf',
      position: 'Propriétaire & gérant',
    },
    {
      name: 'Habib',
      position: 'Chef de centre réparation',
    },
    {
      name: 'Aide Pneumatique',
      position: 'Spécialiste en pneumatique',
    },
    {
      name: 'Aide Tollerie',
      position: 'Spécialiste en tollerie motocycle',
    },
  ];
  return (
    <section className="team">
      <div className="text-center title">
        <h6 className="font__bold">NOS EXPERTES</h6>
        <h2>Les membres de notre équipe</h2>
      </div>
      <Container>
        <Row className="py-4 pt-5">
          {teams.map((team, idx) => (
            <TeamCard key={idx} team={team} />
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Team;
