import React from 'react';
import HeaderMain from '../HeaderMain/HeaderMain';

const Header = () => {
  return (
    <>
      <header>
        <HeaderMain />
      </header>
    </>
  );
};

export default Header;
