import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Huile.css';
import HuileCard from '../HuileCard/HuileCard';

const Huile = () => {
  const vidanges = [
    {
      name: 'IPONE',
      position: 'Réduction de l\'usure des pièces et protection du moteur',
    },
    {
      name: 'MOTOREX',
      position: 'TESTÉ ET APPROUVÉ EN COMPÉTITION, UTILISÉ AU QUOTIDIEN!',
    },
    {
      name: 'MOTUL',
      position: '100% The DRUM',
    },
  ];
  return (
    <section className="huile">
      <div className="text-center title">
        <h6 className="primary__color font__bold">À PROPOS DE PACK VIDANGE HUILES ET FILTRES</h6>
        <h2>À propos de nos pack vidange huiles et filtres</h2>
      </div>
      <Container md={3}>
        <h3>NOS HUILES ET FILTRES MOTEUR</h3>
        <p>Pour toutes motos</p>
        <p>Le moteur et la boîte de vitesses doivent être bien lubrifiés pour bien fonctionner. C’est pourquoi Achraf propose l’huile idéale pour toutes les motos.</p>
        <Row className="review-item rounded">
          {vidanges.map((team, idx) => (
            <HuileCard key={idx} team={team} />
          ))}
        </Row>
      </Container>
      
    </section>
  );
};

export default Huile;
