import React from 'react';
import { Container, Row } from 'react-bootstrap';
import Post1 from '../../../../images/motorx/blog-1.jpg';
import Post2 from '../../../../images/motorx/blog-2.jpg';
import Post3 from '../../../../images/motorx/blog-3.jpg';
import Post4 from '../../../../images/motorx/blog-4.jpg';
import Post5 from '../../../../images/motorx/blog-5.jpg';
import Post6 from '../../../../images/motorx/blog-6.jpg';
import BlogCard from '../BlogCard/BlogCard';
import Footer from '../../../Shared/Footer/Footer';
import NavbarComponent from '../../../Shared/NavbarCompoment/NavbarComponent';
import { Button } from 'react-bootstrap';

const Blog = () => {
  const openWhatsApp = () => {
    const whatsappUrl = `https://wa.me/212689520902?text=Bonjour, Je veux prendre un rendez-vous chez AchrafBike Service.`;
    window.open(whatsappUrl, '_blank');
  };
  const postsformula = [
    {
      name: 'Huile Moto 4 Temps',
      img: Post1,
      text:
        'Huile moteur synthétique de qualité pour motocyclettes 4 temps (synthetic blend). Cette huile offre une très bonne protection du moteur, de la boîte de vitesses et de l’embrayage dans tous les domaines. La validation JASO MA2 garantit un fonctionnement parfait des embrayages à bain d’huile.',
    },
    {
      name: 'Viscosités disponibles',
      img: Post2,
      text:
        '10W-30 / 10W-40 / 10W-50 / 15W-50 / 20W-50',
    },
  ];
  const postsracingpro = [
    {
      name: 'Huile Moto 4 Temps',
      img: Post3,
      text:
        'L’huile moteur MOTOREX RACING PRO 4T 10W/40 CROSS est un produit spécial de hautes performances qui a été spécialement développé pour le MOTOREX Racing Support et donc pour utilisation aussi au plus haut niveau de compétition. Elle possède une stabilité à haute température et une résistance au cisaillement exceptionnelles et réduit efficacement les frottements dans le moteur.blog-',
    },
    {
      name: 'Viscosités disponibles',
      img: Post4,
      text:
        '5W-40 / 10W-30 / 10W-40 / 10W-50 / 10W-60 / 15W-50',
    },
  ];
  const postspowersynt = [
    {
      name: 'Huile Moto 4 Temps',
      img: Post5,
      text:
        '100% Synthétique à base d’esters. Tout-Terrain.',
    },
    {
      name: 'Viscosités disponibles',
      img: Post6,
      text:
        '10W-40 / 10W-50 / 10W-60',
    },
  ];
  return (
    <>
      <NavbarComponent />
      <main>
        <section>
          <div className="text-center title">
            <h6 className="primary__color font__bold">MOTORX</h6>
            <h2>FORMULA 4T SAE MA2</h2>
          </div>
          <Container className="mt-2">
            <Row>
              {postsformula.map((post, idx) => (
              <BlogCard key={idx} post={post} />
              ))}
            </Row>
            <div class="links"><Button className="btn button" onClick={openWhatsApp}>Plus d'infos</Button></div>
          </Container>
        </section>
        <section>
          <div className="text-center title">
            <h6 className="primary__color font__bold">MOTORX</h6>
            <h2>RACING PRO 4T SAE CROSS</h2>
          </div>
          <Container className="mt-2">
            <Row>
              {postsracingpro.map((post, idx) => (
              <BlogCard key={idx} post={post} />
              ))}
            </Row>
            <div class="links"><Button className="btn button" onClick={openWhatsApp}>Plus d'infos</Button></div>
          </Container>
        </section>
        <section>
          <div className="text-center title">
            <h6 className="primary__color font__bold">MOTORX</h6>
            <h2>POWER SYNT 4T SAE MA2</h2>
          </div>
          <Container className="mt-2">
            <Row>
              {postspowersynt.map((post, idx) => (
              <BlogCard key={idx} post={post} />
              ))}
            </Row>
            <div class="links"><Button className="btn button" onClick={openWhatsApp}>Plus d'infos</Button></div>
          </Container>
        </section>
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
};

export default Blog;
