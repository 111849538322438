import React from 'react';
import { Container, Row } from 'react-bootstrap';
import Post1 from '../../../images/blog-1.jpg';
import Post2 from '../../../images/blog-2.jpg';
import Post3 from '../../../images/blog-3.jpg';
import BlogCard from '../BlogCard/BlogCard';

const Blog = () => {
  const posts = [
    {
      name: 'Top 10 des avancées motocycle à espérer',
      img: Post1,
      text:
        'Anticipez les futures révolutions motocyclistes avec les 10 prochaines avancées majeures : des technologies électriques révolutionnaires, des systèmes de sécurité intelligents, et une conception ergonomique repensée pour une expérience de conduite inégalée.',
    },
    {
      name: 'Que faut-il savoir avant de choisir un atelier de réparation motocycle',
      img: Post2,
      text:
        'Avant de choisir un atelier de réparation motocycle, assurez-vous de considérer les compétences de l\'équipe technique, les avis clients, et les certifications professionnelles. Optez pour un atelier réputé, capable de garantir un service fiable, des diagnostics précis, et une expertise approfondie pour assurer la performance optimale de votre motocycle.',
    },
    {
      name: 'Quel est le coût de la réparation d’une maintenance général sur une moto ?',
      img: Post3,
      text:
        'Découvrez le coût estimatif d\'une réparation lors d\'une maintenance générale pour votre moto. Facteurs à considérer : modèle de la moto, étendue des services, et la main-d\'œuvre qualifiée. Obtenez un devis détaillé pour une estimation précise et transparente des coûts de réparation.',
    },
  ];
  return (
    <section>
      <div className="text-center title">
        <h6 className="primary__color font__bold">RECENT NEWS</h6>
        <h2>Notre histoire de réussite</h2>
      </div>
      <Container className="mt-4">
        <Row>
          {posts.map((post, idx) => (
            <BlogCard key={idx} post={post} />
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Blog;
