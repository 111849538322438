import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
      <Redirect
      to={{
        pathname: '/login',
        state: { from: location },
      }}
    />
      }
    />
  );
};

export default PrivateRoute;
