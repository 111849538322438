import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import AboutUs from '../../../images/about.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './About.css';
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faWhatsapp,
  faGoogle
} from '@fortawesome/free-brands-svg-icons';

const About = () => {
  const socialMediaLinks = {
    facebook: 'https://web.facebook.com/profile.php?id=100063535481731',
    twitter: 'https://twitter.com/bikeachraf',
    instagram: 'https://www.instagram.com/bikeachraf',
    whatsapp: 'https://api.whatsapp.com/send?phone=0689520902',
    google: 'mailto:abourkhaachraf@gmail.com?subject=Rendez vous chez Achraf bike'
  };

  return (
    <section className="about">
      <div className="text-center title">
        <h6 className="primary__color font__bold">À PROPOS DE NOUS</h6>
        <h2>À propos de nos services</h2>
      </div>
      <Container md={4}>
        <Row>
          <div className="review-item rounded" >
            {/* <img class="rounded img-with-padding" src={AboutUs} alt="demo" /> */}
            <Col md={{ span: 10 }} className="my-2">
                <h3>Bienvenue à notre centre</h3>
                <p>
                Votre moto est un bijou mécanique qui mérite d'être entretenue régulièrement pour garantir sa sécurité et sa longévité. Un entretien régulier permet de prévenir les pannes et de garantir les performances de votre moto.
                </p>
                <p>
                Chez <b>Achraf Bike</b>, nous mettons à votre disposition notre expertise et notre savoir-faire pour l'entretien et la vidange de votre moto. Nos mécaniciens qualifiés utilisent des pièces et des fluides de qualité pour garantir un travail impeccable.
                </p>
                <div className="icon justify-content-center">
                  <a href={socialMediaLinks.facebook} target="_blank" rel="noopener noreferrer" className="image-container">
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                  <a href={socialMediaLinks.twitter} target="_blank" rel="noopener noreferrer" className="image-container">
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                  <a href={socialMediaLinks.instagram} target="_blank" rel="noopener noreferrer" className="image-container">
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                  <a href={socialMediaLinks.whatsapp} target="_blank" rel="noopener noreferrer" className="image-container">
                    <FontAwesomeIcon icon={faWhatsapp} />
                  </a>
                  <a href={socialMediaLinks.google} target="_blank" rel="noopener noreferrer" className="image-container">
                    <FontAwesomeIcon icon={faGoogle} />
                  </a>
                </div>
              </Col>
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default About;
