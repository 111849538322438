import React, { useEffect, useState } from 'react';

const Services = () => {

  return (
    <section>
      <div className="text-center title">
        <h6 className="font__bold">NOS SERVICES</h6>
        <h2>Nos meilleurs services </h2>
      </div>
    </section>
  );
};

export default Services;
