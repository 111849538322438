import React from 'react';
import './HeaderMain.css';
import { Button } from 'react-bootstrap';

const HeaderMain = () => {

  const openWhatsApp = () => {
    const whatsappUrl = `https://wa.me/212689520902?text=Bonjour, Je veux prendre un rendez-vous chez AchrafBike Service.`;
    window.open(whatsappUrl, '_blank');
  };
  return (
    <div className="banner">
      <div className="banner__content">
        <h4>
          Vous nous faites confiance votre réparation et maintenance moto au meilleur centre à casablanca
        </h4>
        <p>
        Votre moto est un bijou mécanique qui mérite d'être entretenue régulièrement pour garantir sa sécurité et sa longévité. Un entretien régulier permet de prévenir les pannes et de garantir les performances de votre moto.
        </p>
        <p>
        Chez <b>Achraf bike</b>, nous mettons à votre disposition notre expertise et notre savoir-faire pour l'entretien et la vidange de votre moto. Nos mécaniciens qualifiés utilisent des pièces et des fluides de qualité pour garantir un travail impeccable.
        </p>
        <Button className="btn button" onClick={openWhatsApp}>Réservation en ligne</Button>
      </div>
    </div>
  );
};

export default HeaderMain;
