import React from 'react';

const Review = () => {
  return (
    <section className="review ">
      <div className="text-center title">
        <h6 className="font__bold">AVIS CLIENT</h6>
        <h2>Avis des clients aprés réparation chez Achraf bike</h2>
      </div>
    </section>
  );
};

export default Review;
