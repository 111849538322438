import React from 'react';
import Footer from '../../Shared/Footer/Footer';
import About from '../About/About';
import Blog from '../Blog/Blog';
import Header from '../Header/Header';
import Review from '../Review/Review';
import Services from '../Services/Services';
import Team from '../Team/Team';
import Huile from '../Huile/Huile';
import NavbarComponent from '../../Shared/NavbarCompoment/NavbarComponent';

const Home = () => {
  return (
    <>
      <NavbarComponent />
      <Header />
      <main>
        <About />
        <Huile />
        <Services />
        <Review />
        <Team />
        <Blog />
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
};

export default Home;
