import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './NavbarComponent.css';
import { NavLink } from 'react-router-dom';

const NavbarComponent = () => {
  return (
    <Navbar className="menu fixed-top" bg="dark" expand="lg">
      <Container>
        <Navbar.Brand>
          <Link className="links__color menu__icon primary__color" to={'/'}>
          <img src="logo.png" alt=""></img>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto menu__items">
            <Link className="links__color" to={'/'}>
              Acceuil
            </Link>
            <NavLink className="links__color" to={'/'} exact>
              Huile Motor
              <ul>
                <li>
                  <NavLink className="sub-links__color" to={'/oil-change/ipone'}>
                    IPONE
                  </NavLink>
                </li>
                <li>
                  <NavLink className="sub-links__color" to={'/oil-change/motorx'}>
                    MOTOREX
                  </NavLink>
                </li>
                <li>
                  <NavLink className="sub-links__color" to={'/oil-change/motul'}>
                    MOTUL
                  </NavLink>
                </li>
              </ul>
            </NavLink>
            <Link className="links__color" to={'/'}>
              Service en ligne
            </Link>
            <Link className="links__color" to={'/admin/manage-service'}>
              Manage Service
            </Link>
            <Link className="links__color" to={'/admin/book-list'}>
                  Booking Liste
                </Link>
                <Link className="links__color" to={'/admin/addReview'}>
                  Review
                </Link>
            {/* {loggedInUser.email ? (
              <>
                <a>
                  <span className="primary__color">
                    <img
                      style={{ width: '35px', borderRadius: '50%' }}
                      src={loggedInUser.photo}
                      alt=""
                    />
                  </span>
                </a>
                <Button
                  onClick={handleLogOut}
                  className="btn button"
                >
                  <a href="/" className="btn__link">
                    Log Out
                  </a>
                </Button>
              </>
            ) : (
              <Button className="btn button">
                <Link className="btn__link" to={'/login'}>
                Se connecter
                </Link>
              </Button>
            )} */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
