import React from 'react';

const Services = () => {

  return (
    <section>
        <div class="content-blocs">
            <div class="sc-vrh5u3-0 lirSMV">
                <a class="sc-vrh5u3-1 dwhgej container" href="/fr/eshop/produit/stroke-4-racing-10w40">
                    <div class="media">
                        <div class="image hover gatsby-image-wrapper" >
                            <div aria-hidden="true" ></div>
                            <picture>
                            </picture>
                        </div>
                    </div>
                    <div class="text grey">
                        <h3 class="sc-vrh5u3-2 dUDBvt">STROKE 4 RACING</h3>
                        <div class="sc-vrh5u3-3 dWoPUj">Huile Moto 4 Temps 100% Synthétique à base d’esters Partenaire de la Red Bull MotoGP Rookies Cup</div>
                        <div class="image packshot gatsby-image-wrapper" >
                            <div aria-hidden="true" ></div>
                            <picture>
                            </picture>
                        </div>
                    </div>
                </a>
                <div class="sc-vrh5u3-1 dwhgej container">
                    <div class="media features">
                        <div class="sc-1gkhqeq-0 eulRxv">
                            <div class="feature"><span>Puissance &amp; performances en compétition</span></div>
                            <div class="feature"><span>Utilisée pendant 24 heures en Championnat du monde d'Endurance</span></div>
                            <div class="feature"><span>Développée avec la Red Bull MotoGP Rookies Cup</span></div>
                            <div class="feature"><span>Réduction de l'usure des pièces et protection du moteur</span></div>
                        </div>
                    </div>
                    <div class="text">
                        <div class="sc-40d92j-0 haohzD content viscosity" >
                            <p>Viscosités disponibles</p>
                            <p><strong>5W-40 / 10W-40 / 10W-50 / 15W-50</strong></p>
                        </div>
                        <div class="links"><a class="simple-link red more" href="/fr/eshop/produit/stroke-4-racing-10w40">Plus d'infos</a><a class="simple-link block buy" href="/fr/eshop/produit/stroke-4-racing-10w40">Acheter</a></div>
                    </div>
                </div>
            </div>
            <div class="sc-vrh5u3-0 lirSMV">
                <a class="sc-vrh5u3-1 dwhgej container" href="/fr/eshop/produit/stroke-4-racing-10w40">
                    <div class="media">
                        <div class="image hover gatsby-image-wrapper" >
                            <div aria-hidden="true" ></div>
                            <picture>
                            </picture>
                        </div>
                    </div>
                    <div class="text grey">
                        <h3 class="sc-vrh5u3-2 dUDBvt">STROKE 4 RACING</h3>
                        <div class="sc-vrh5u3-3 dWoPUj">Huile Moto 4 Temps 100% Synthétique à base d’esters Partenaire de la Red Bull MotoGP Rookies Cup</div>
                        <div class="image packshot gatsby-image-wrapper" >
                            <div aria-hidden="true" ></div>
                            <picture>
                            </picture>
                        </div>
                    </div>
                </a>
                <div class="sc-vrh5u3-1 dwhgej container">
                    <div class="media features">
                        <div class="sc-1gkhqeq-0 eulRxv">
                            <div class="feature"><span>Puissance &amp; performances en compétition</span></div>
                            <div class="feature"><span>Utilisée pendant 24 heures en Championnat du monde d'Endurance</span></div>
                            <div class="feature"><span>Développée avec la Red Bull MotoGP Rookies Cup</span></div>
                            <div class="feature"><span>Réduction de l'usure des pièces et protection du moteur</span></div>
                        </div>
                    </div>
                    <div class="text">
                        <div class="sc-40d92j-0 haohzD content viscosity" >
                            <p>Viscosités disponibles</p>
                            <p><strong>5W-40 / 10W-40 / 10W-50 / 15W-50</strong></p>
                        </div>
                        <div class="links"><a class="simple-link red more" href="/fr/eshop/produit/stroke-4-racing-10w40">Plus d'infos</a><a class="simple-link block buy" href="/fr/eshop/produit/stroke-4-racing-10w40">Acheter</a></div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
};

export default Services;
