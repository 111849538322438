import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAlt,
  faEnvelope,
  faPhoneAlt,
} from '@fortawesome/free-solid-svg-icons';
import './Footer.css';
const Footer = () => {
  return (
    <Container>
      <Row className="py-4">
        <Col md={10} lg={3}>
          <h3 className="links__color menu__icon primary__color">ACHRAF BIKE SERVICE</h3>
          <div className="footer__icon">
            <ul>
              <li>
                <span>
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                </span>
                Bd Oued Sebou Oulfa Casablanca, MA
              </li>
              <li>
                <span>
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
                achraf.motoservice@gmail.com
              </li>
              <li>
                <span>
                  <FontAwesomeIcon icon={faPhoneAlt} />
                </span>
                +212-6895-20902
              </li>
            </ul>
          </div>
        </Col>
        <Col md={10} lg={3}>
          <h3>Notre Service</h3>
          <ul className="f__service">
            <li>SERVICE RAPIDE</li>
            <li>PNEUMATIQUE</li>
            <li>CARROSSERIE & PEINTURE</li>
            <li>CHECK & DIAGNOSTIC MOTEUR</li>
            <li>LUBRIFIANT, HUILE ET FILTRES</li>
            <li>REMONTAGE MOTOR</li>
            <li>COURROIES ET TUYAUX</li>
            <li>MOTO WASHING</li>
          </ul>
        </Col>
        <Col md={10} lg={2}>
          <h3>Notre équipe</h3>
          <ul className="f__service">
            <li>Achraf</li>
            <li>Habib</li>
          </ul>
        </Col>
        <Col md={10} lg={2}>
          <h3>Notre Conseils</h3>
          <ul className="f__service">
            <li>ACHATS</li>
            <li>VERIFICATION</li>
            <li>DEVIS</li>
          </ul>
        </Col>
        <Col md={10} lg={2}>
          <h3>Heure de travail</h3>
          <ul className="f__workingTime">
            <li>
              <span>Lundi</span>
              <span>8:00 - 20:00</span>
            </li>
            <li>
              <span>Mardi</span>
              <span>8:00 - 20:00</span>
            </li>
            <li>
              <span>Mercredi</span>
              <span>8:00 - 20:00</span>
            </li>
            <li>
              <span>Jeudi</span>
              <span>8:00 - 20:00</span>
            </li>
            <li>
              <span>Vendredi</span>
              <span>8:00 - 20:00</span>
            </li>
            <li>
              <span>Samedi</span>
              <span>8:00 - 12:00</span>
            </li>
          </ul>
        </Col>
      </Row>
      <p className="text-center d-block">
        Copy Right &copy; 2023 | All Right Reserved By Meziane Othmane
      </p>
    </Container>
  );
};

export default Footer;
