import React from 'react';
import './Oil.css';
import NavbarComponent from '../../Shared/NavbarCompoment/NavbarComponent';
import Services from './Services';
const Oil = () => {
  
  return (
    <>
      <header>
        <NavbarComponent />
        <Services />
      </header>
    </>
  );
  
};

export default Oil;
